import React, {useEffect, useState} from 'react';
import { withStyles,
	Card, CardContent
} from '@material-ui/core';
import ReactIframeResizer from 'react-iframe-resizer-super';
import Footer from '../Footer';
import EventHeader from '../EventHeader';

function EventRegister(props){
	const[event, setEventInfo] = useState({})
	//08e0
	useEffect(() => {
		loadEventInfo()
	}, [])

	let loadEventInfo = () => {
		fetch(`https://connect.eventtia.com/en/api/v2/events/search_by_uri/${props.match.params.event_uri}?`)
		//fetch(`http://192.168.1.5:3001/en/api/v2/dbf4ecc7-f29a-4c28-9/events/list?page_size=9&page=${page}&keywords=${keyword}`)
		.then(results => results.json())
		.then((data) => {
			setEventInfo(data)
		})
	}

  return(
		<div className={props.classes.eventDetails}>
			{Object.keys(event).length !== 0 ? (
				<div>  
					<EventHeader
						event={event}
					/>
					<div className='details'>
						<Card className='card-info' style={{borderRadius: '20px 20px 0 0', 	marginBottom: '10%'}}>
							<CardContent style={{padding: 'unset'}}>
								<ReactIframeResizer 
									src={`https://connect.eventtia.com/es/public/events/${event.event_uri}/registration/attendees`}
									className={props.classes.iframe}
								/>
							</CardContent>
						</Card>
					</div>
				</div>
			): (
				null
			)}
			<Footer />
		</div>
	)
}

const styleSheet = theme => ({
	eventDetails: {
		'& .header': {
			color: 'white'
		},
		'& .details': {
			margin: '-3% 10%',
			'& .card-info': {
				'& .right-side': {
					'& .location-info': {
						display: 'flex',
						justifyContent: 'space-between',
						[theme.breakpoints.down('sm')]: {
							flexWrap: 'wrap'
						}
					}
				}
			},
			'& .contact': {
				height: '8vh',
				backgroundColor: '#1695A3', 
				marginBottom: '5%', 
				color: 'white',
				display: 'flex', 
				justifyContent: 'space-evenly',
				padding: '10px',
				alignItems: 'center'
			}
		}
	},
	button: {
		backgroundColor: '#FFF000 !important',
		color: 'black',
		height: '40px',
		width: '142px',
		fontWeight: 'bold'
	},
	detailsBody: {
		display: 'flex',
		padding: '16px',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap'
		}
	},
	title: {
		marginLeft: '10%', 
		fontWeight: '700', 
		fontFamily: 'Lato, sans-serif', 
		fontStyle: 'normal', 
		fontSize: '38px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '22px'
		}
	}
})

export default withStyles(styleSheet)(EventRegister)