import React, {useState, useEffect, useRef} from 'react';
import { withStyles, TextField, Card, 
	CardContent, Grid, MenuItem, Button, 
	Tabs, Tab, Paper, Dialog
} from '@material-ui/core';
import {
  BrowserView,
  MobileView,
	isBrowser,
} from "react-device-detect";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FilterIcon from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Pagination from "material-ui-flat-pagination";
import Carousel from '../Carousel';
import EventCard from '../EventCard';
import Footer from '../Footer';
import rightLabel from '../../assets/images/right_label.svg';
import leftLabel from '../../assets/images/left_label.svg';

const Home = (props) => {
	const[events, setEvents] = useState([]);
	const[futureEvents, setFutureEvents] = useState([]);
	const[eventSegmentations, setEventSegmentations] = useState([]);
	const[totalEvents, setTotalEvents] = useState(0);
	const[offset, setOffset] = useState(0);
	const[tabValue, setTabValue] = useState(0);
	const[executionStatus, setExecutionStatus] = useState('upcoming');
	const [open, setOpen] = React.useState(false);
	const refKeywords = useRef()
	const[filterValues, setFilterValues] = useState({
		keywords: '',
		category_id: '',
		date: null,
	})

	useEffect(() => {
		loadFutureEvents()
		// loadAccountSegmentations()
	}, []);

	let loadEventsInfo = (page) => {
		let keywords = refKeywords.current.value;
		let start_date = filterValues.date === null ? '' : new Date(filterValues.date).toDateString();
		let category_id = filterValues.category_id;
		let execution_status = executionStatus;
		fetch(`https://connect.eventtia.com/en/api/v2/52d4f0ac-b4c0-4056-8/events/list?page_size=12&page=${page}&keywords=${keywords}&start_date=${start_date}&category_id=${category_id}&execution_status=${execution_status}&status=active`)
		// fetch(`http://localhost:3001/en/api/v2/C1f8c4c4-4b15-4e2e-b/events/list?page_size=12&page=${page}&keywords=${keywords}&start_date=${start_date}&category_id=${category_id}&execution_status=${execution_status}&status=active`)
		.then(results => results.json())
		.then((data) => {
			console.log(data.events)
			setEvents(data.events)
			setTotalEvents(data.total_events)
		})	
	}
	
	useEffect(() => {
		if(filterValues.keywords === '' && filterValues.category_id === '' && filterValues.date === null){
			loadEventsInfo(1)
		} 
	}, [filterValues]);

	useEffect(() => {
		loadEventsInfo(1)
	}, [executionStatus])	

	let loadFutureEvents = () => {
		fetch(`https://connect.eventtia.com/en/api/v2/52d4f0ac-b4c0-4056-8/events/list?page_size=5&&execution_status=upcoming&featured=true&status=active`)
		// fetch(`http://localhost:3001/en/api/v2/C1f8c4c4-4b15-4e2e-b/events/list?page_size=5&&execution_status=upcoming&featured=true&status=active`)
		.then(results => results.json())
		.then((data) => {
			setFutureEvents(data.events)
		})	
	}

	let handlePageClick = (offset, page) => {
		setOffset(offset)
		loadEventsInfo(page)
	}

	let accountSegmentations = () => {
		let seg = [{value: '', label: ''}]
		if(eventSegmentations.length > 0){
			eventSegmentations.forEach((item, key) => {
				seg.push({value: item.id, label: item.name})
			})
		}
		return seg
	}

	const handleChange = name => event => {
		if(name === 'date') {
			setFilterValues({ ...filterValues, [name]: event });
		}else{
			setFilterValues({ ...filterValues, [name]: event.target.value });
		}
	}
	
	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue)
		switch(newValue) {
			case 0:
				return setExecutionStatus('upcoming');
			case 1: 
				return setExecutionStatus('all');
			case 2: 
				return setExecutionStatus('past');
			case 3:
				return setExecutionStatus('current');
		}
	}

	let searchEvents = () => {
		loadEventsInfo(1)
	}

	let searchMobile = () => {
		loadEventsInfo(1)
		setOpen(false)
	}

	let clearFilters = () => {
		refKeywords.current.value = '';
		setFilterValues({
			keywords: '',
			category_id: '',
			date: null
		});
	}


  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }


	let cardEventsListHeader = () => {
		return(
			<div className='header'>
				<div className='title'>
					<div style={{fontWeight: '700', fontSize: '25px', fontFamily: 'Lato, sans-serif', fontStyle: 'normal'}}>Webinaires à venir</div>
					<BrowserView>
						<div className={props.classes.clearFilters} onClick={() => clearFilters()}>Masquer les filtres</div>
					</BrowserView >
					<MobileView>
						<FilterIcon style= {{color: '#c64f0c'}} onClick={handleClickOpen}/>
					</MobileView>
				</div>
				<div className='filters'>
					<TextField
						id="outlined-bare"
						margin="normal"
						variant="outlined"
						placeholder='Mots clés'
						className={props.classes.textField}
						inputRef={refKeywords}
					/>
					{isBrowser ? (
						<KeyboardDatePicker
							margin="normal"
							id="mui-pickers-date"
							label=""
							className={props.classes.datePicker}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							onChange={handleChange('date')}
							value={filterValues.date}
							format="MM/dd/yyyy"
							placeholder="MM/DD/YYYY"
							invalidDateMessage="Invalid format"
						/>
					) : (
						null
					)}
					{isBrowser ? (
						<TextField
							id="outlined-select-currency"
							select
							label="Category"
							className={props.classes.select}
							style={{display: 'none'}}
							SelectProps={{
								MenuProps: {
									className: props.classes.menu,
								},
							}}
							helperText=""
							margin="normal"
							variant="outlined"
							onChange={handleChange('category_id')}
							value={filterValues.category_id}
						>
							{accountSegmentations().map(option => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					) : (
						null
					)}
					<Button variant="contained" color="secondary" className={props.classes.button} onClick={() => searchEvents()}>
						<BrowserView>Rechercher</BrowserView>
						<MobileView><Search style={{cursor: 'pointer'}} /></MobileView>
					</Button>
					<MobileView>
						<div className={props.classes.clearFilters} onClick={() => clearFilters()}>Masquer les filtres</div>
					</MobileView>
				</div>
			</div>
		)
	}

	let mobileFilters = () => {
		return(
			<Dialog fullScreen open={open} onClose={handleClose} className={props.classes.mobileFiltersContainer}>
				<div className='header' style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
					<p>Filtres de l'événement</p>
					<IconButton
						edge="start"
						color="inherit"
						onClick={handleClose}
						aria-label="Close"
						style={{color: '#c64f0c', padding: 0}}
					>
						<CloseIcon />
					</IconButton>
				</div>
				<div>
					<KeyboardDatePicker
						margin="normal"
						id="mui-pickers-date"
						label=""
						className={props.classes.datePicker}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
						onChange={handleChange('date')}
						value={filterValues.date}
						format="MM/dd/yyyy"
						placeholder="10/10/2018"
						invalidDateMessage="Invalid format"
					/>
					<TextField
						id="outlined-select-currency"
						select
						label="Category"
						className={props.classes.select}
						style={{display: 'none'}}
						SelectProps={{
							MenuProps: {
								className: props.classes.menu,
							},
						}}
						helperText=""
						margin="normal"
						variant="outlined"
						onChange={handleChange('category_id')}
						value={filterValues.category_id}
					>
						{accountSegmentations().map(option => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
					<Button variant="contained" color="secondary" className={props.classes.button} style={{width: '100%', borderRadius: 'unset'}} onClick={() => searchMobile()}>
						Rechercher
					</Button>
				</div>
			</Dialog>
		)
	}
	return(
		<div className="App">
			<div className='body'>
				{(futureEvents.length > 0) ?
					<Carousel
						events={futureEvents}
					/>
					:(null)
				}
				<Card className={props.classes.eventsCard}>
					{ cardEventsListHeader() }
					<CardContent>
						<Paper style={{boxShadow: 'unset'}}>
							<Tabs
									value={tabValue}
									onChange={handleChangeTab}
									indicatorColor="white"
									textColor="primary"
									scrollButtons='auto'
									className={props.classes.tabs}
								>
									<Tab label="Webinaires à venir" className={props.classes.tabStyles} />
									<Tab label="Tous" className={props.classes.tabStyles} />
									<Tab label="Webinaires Passées " className={props.classes.tabStyles} />
									<Tab label="Webinaires Actuels" className={props.classes.tabStyles} />
							</Tabs>
						</Paper>
						<Grid container>
							{events.map((item, key) => (
								(item.id === 23761 || item.id === 26588) ? (null) : (
									<Grid item xs={12} sm={3} key={key}>
										<EventCard
											event={item}
										/>
									</Grid>
								)
							))}
						</Grid>
						<div className='pagination-section' style={{marginTop: '10px'}}>
							<Pagination
								limit={8}
								offset={offset}
								total={totalEvents}
								onClick={(event, offset, page) => handlePageClick(offset,page)}
								classes={{text: props.classes.paginationText, rootCurrent: props.classes.paginationCurrent}}
								nextPageLabel={<img src={rightLabel} alt='rig'></img>}
								previousPageLabel={<img src={leftLabel} alt='left'></img>}
							/>
						</div>
					</CardContent>
				</Card>
				{mobileFilters()}
			</div>
			<Footer />
		</div>
	)
}

const styleSheet = theme => ({
	carousel: {
		height: '50vh',
		'& .image-gallery-image img': {
			height: '50vh'
		}
	},
	eventsCard: {
		marginLeft: '10%',
		marginRight: '10%',
		marginBottom: '5%',
		borderRadius: '20px 20px 0 0',
		position: 'relative',
		marginTop: '1%',
		boxShadow: '0px 1px 20px 0px rgba(0,0,0,0.07), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.06)',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0',
			marginRight: '0',
			marginTop: '0',
			marginBottom: '5%',
			borderRadius: 'unset'
		},
		'& .header': {
			backgroundColor: '#1D3551',
			color: 'white',
			padding: '5px 10px',
			'& .title': {
				display: 'flex',
				justifyContent: 'space-between',
				padding: '5px 30px 5px 20px',
				[theme.breakpoints.down('sm')]: {
					padding: '10px 20px 10px 12px'
				}
			},
			'& .filters': {
				///padding: '10px',
				display: 'flex',
				padding: '0 16px',
				justifyContent: 'space-between',
				alignItems: 'center',
				'& .MuiFormControl-root': {
					marginRight: '10px',
					padding: '0 !important',
					[theme.breakpoints.down('sm')]: {
						marginRight: 0
					}
				},
				[theme.breakpoints.down('sm')]: {
					flexWrap: 'wrap',
					justifyContent: 'flex-start'
				}
			}
		}
	},
	eventsTimeFilter: {
		display: 'none',
		justifyContent: 'space-evenly',
		padding: '20px',
		fontFamily: 'Lato, sans-serif', 
		fontWeight: '400',
		fontSize: '15px', 
		fontStyle: 'normal',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap'
		},
		'& button': {
			borderRadius: '30px',
			textTransform: 'none',
			borderColor: '#1D3551',
			width: '20%',
			[theme.breakpoints.down('sm')]: {
				width: '50% !important', 
				marginBottom: '5px'
			},
		}
	},
	textField: {
		width: '25%',
		color: 'black',
		fontSize: '10px',
		[theme.breakpoints.down('sm')]: {
			width: '70%',
		},
		backgroundColor: 'white',
		borderRadius: '4px', 
		height: '40px',
		'& div': {
			height: '40px'
		},
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
	},
	datePicker: {
		width: '25%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		backgroundColor: 'white',
		marginLeft: '3px',
		borderRadius: '4px',
		height :'40px',
		'& div': {
			height: '40px'
		},
		'& div .MuiInputBase-input':{
			height: '1.6875em',
			paddingLeft: '20px'
		}
	},
	select: {
		width: '25%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		backgroundColor: 'white',
		borderRadius: '4px',
		marginLeft: '3px',
		height: '40px',
		lineHeight: '40px',
		'& div': {
			height: '40px'
		},
		'& div div': {
			display: 'flex',
			alignItems: 'center'
		},
		'& .MuiInputLabel-shrink': {
			display: 'none'
		},
		'& label': {
			transform: "translate(14px, 15px) scale(1)"
		},
		'& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
				borderColor: 'black',
				'& label': {
					display: 'none'
				}
			},
			'& fieldset': {
				display: 'none'
			}
    },
	},
	button: {
		width: '15%',
		height: '40px',
		fontFamily: 'Lato, sans-serif',
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: '16px',
		marginTop: '17px',
		borderRadius: 'unset',
		[theme.breakpoints.down('sm')]: {
			width: '35%',
			
		},
		margin: '8px',
		backgroundColor: '#9CD0AC !important',
		color: 'white'
	},
	tabStyles: {
		borderRadius: '30px',
		border: '1px solid #1D3551',
		margin: '0 3%',
		textTransform: 'capitalize',
		[theme.breakpoints.down('sm')]: {
			margin: '0 3% 10px 0'
		}
	},
	tabs:{
		display: 'flex',
		justifyContent: 'space-evenly', 
		width: '100% !important',
		'& .MuiTabs-flexContainer': {
			width: '100%',
			//margin: '15px 0',
			'& .MuiButtonBase-root': {
				minHeight: '30px',
			},
			justifyContent: 'center',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'center',
				flexWrap: 'wrap'
			}
		},
		'& .Mui-selected': {
			color: 'white',
			backgroundColor: '#1D3551'
		}
	},
	paginationText: {
		color: 'black'
	},
	paginationCurrent: {
		backgroundColor: '#F9F5EF',
		borderRadius: '50%',
		width: '32px',
		height: '32px',
		color: '#1D3551'
	},
	activeButton: {
		backgroundColor: '#1D3551',
		color: 'white'
	},
	appBarModal: {
		position: 'relative',
		backgroundColor: '#1D3551',
		padding: '20px'
	},
	mobileFiltersContainer: {
		'& .MuiDialog-container .MuiPaper-root': {
			backgroundColor: '#1D3551',
			padding: '20px'
		}
	},
	clearFilters: {
		color: 'white !important',
		fontWeight: '700',
		fontSize: '15px',
		fontFamily: 'Lato, sans-serif',
		textDecoration: 'underline',
		cursor: 'pointer'
	}
})

export default withStyles(styleSheet)(Home);