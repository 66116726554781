import React from 'react';
import { withStyles } from '@material-ui/core'; 
// import { Link } from 'react-router-dom';
import facebook from '../assets/images/facebook.svg';
import instragram from '../assets/images/instagram.svg';
import twitter from '../assets/images/twitter.svg';
import youtube from '../assets/images/youtube-icon.svg';
// import privacy from '../assets/files/protection.docx';
import logoFEE from '../assets/images/Logo_FR_couleur_fond.jpg';
// import vimeo from '../assets/images/Vimeo.svg';
// import snapchat from '../assets/images/Snapchat.svg';
import linkedin from '../assets/images/LinkedIn.svg';
function Footer(props){
  return(
		<div>
			<div className={props.classes.footerSection1}>
				<div className="idom-logo" ><img src={logoFEE} style={{width: '150px', paddingTop: 15}} alt='idom-logo'/></div>
				<div className='contact-section'>
					<div className='contact-title' >Contact</div>
					<div className='contact-info'>
						<p style={{margin: '0px !important'}}>
							France Renouvelables<br/>
							5 Avenue de la République<br/>
							75011 Paris<br/>
						</p>	
						<p><a href="https://live.eventtia.com/fr/conditions" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#1D3551'}}>Termes et Conditions</a></p>
						{/* <p><a href="https://fee.asso.fr/nos-valeurs/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: 'white'}}>Charte éthique</a></p> */}
						<p><a href="https://www.france-renouvelables.fr/protection-des-donnees-personnelles/" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#1D3551'}}>Charte de protection des données personnelles</a></p>
						<p><a href="https://live.eventtia.com/fr/conditions/FAQ" target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#1D3551'}}>FAQ</a></p>
				
					</div>
				</div>
				<div className='socials-info'>
					<div className='socials-title' style={{display: 'none'}}>REDES SOCIALES</div>
					<div className='socials-container'>
						<p><a href='https://www.facebook.com/fr.renouvelables/' target='_blank' rel="noopener noreferrer"><img src={facebook} alt='face'></img></a></p>
						<p><a href='https://www.instagram.com/france_renouvelables' target='_blank' rel="noopener noreferrer"><img src={instragram} alt='ins'></img></a></p>
						<p><a href='https://twitter.com/f_renouvelables' target='_blank' rel="noopener noreferrer"><img src={twitter} alt='twi'></img></a></p>
						<p><a href='https://www.youtube.com/@FranceRenouvelables' target='_blank' rel="noopener noreferrer"><img src={youtube} alt='youtu'></img></a></p>
						<p><a href='https://www.linkedin.com/company/france-renouvelables/mycompany/' target='_blank' rel="noopener noropneeferrer"><img src={linkedin} alt='linkedin'></img></a></p>
					</div>
				</div>
			</div>
		</div>
	)
}

const styleSheet = theme => ({
	footerSection1: {
		backgroundColor: '#F9F5EF', 
		display: 'flex', 
		justifyContent: 'space-between',
		paddingLeft: '10%',
		paddingRight: '10%',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap',
			justifyContent: 'center'
		},
		alignItems: 'center',
		'& .idom-logo': {
			textAlign: 'left !important',
			width: '50%',
			'& img': {
				position: 'relative',
				width: '50%'
			}
		},
		'& .contact-section': {
			'& .contact-title': {
				color: '#1D3551', 
				fontSize: '20px', 
				fontWeight: 'bold',
				display: 'none'
			},
			'& .contact-info': {
				color: '#1D3551',
				fontSize: '16px'
			}
		},
		'& .socials-info': {
			width: '20%',
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			},
			'& .socials-title': {
				fontSize: '.75rem',
				color: '#1D3551'
			},
			'& .socials-container': {
				display: 'flex', 
				justifyContent: 'space-around', 
			}
		}
	}
});

export default withStyles(styleSheet)(Footer);