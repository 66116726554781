import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  AppBar, Toolbar, Typography, List, ListItem,
  withStyles, Grid, SwipeableDrawer
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';

//assets
//import logo from '../assets/images/navbar-logo.png'
import logo from '../assets/images/Logo_FR_couleur_fond.jpg'
const styleSheet = {
  list : {
    width : 200,
  },
  padding : {
    paddingRight : 30,
    cursor : "pointer",
    fontSize: '16px',
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400'
  },

  sideBarIcon : {
    padding : 0,
    color : "black",
    cursor : "pointer",
  }
}

function Navbar(props){
  const [drawerActive, setDrawerActive] = useState(false);
  const [drawer, setDrawer] = useState(false);
  useEffect(() => {
    
    if(window.innerWidth <= 600){
      setDrawerActive(true)
    }

    window.addEventListener('resize',()=>{
      if(window.innerWidth <= 600){
        setDrawerActive(true)
      }
      else{
        setDrawerActive(false)
      }
    });
  }, []);

  //Small Screens
  let createDrawer = () => {
    return (
      <div>
        <AppBar style={{backgroundColor: '#F9F5EF', color:'black'}} position= 'static'>
          <Toolbar>
            <Grid container direction = "row" justify = "space-between" alignItems="center">
              <MenuIcon
                className = {props.classes.sideBarIcon}
                onClick={()=>{setDrawer(true)}} />

              <Typography color="inherit" variant = "headline"><Link to='/'><img style={{height: '40px'}} src={logo} alt='lo'></img></Link></Typography>
              <Typography color="inherit" variant = "headline"></Typography>
            </Grid>
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
         open={drawer}
         onClose={()=>{setDrawer(false)}}
         onOpen={()=>{setDrawer(true)}}
         style={{backgroundColor: '#F9F5EF', color:'black'}}>

           <div
             tabIndex={0}
             role="button"
             onClick={()=>{setDrawer(false)}}
             onKeyDown={()=>{setDrawer(false)}}>

            <List className = {props.classes.list}>
              <ListItem key = {1} button divider><Link to='/' style={{textDecoration: 'none', color: 'black', width: '100%'}}>Accueil </Link></ListItem>
              <ListItem key = {2} button divider><Link to={{pathname: 'https://live.eventtia.com/fr/conditions/Contact'}} target='_blank' style={{textDecoration: 'none', color: 'black', width: '100%'}}>Contact </Link></ListItem>
             </List>

         </div>
       </SwipeableDrawer>

      </div>
    );
  }

  //Larger Screens
  let destroyDrawer = () => {
    const {classes} = props
    return (
      <AppBar style={{backgroundColor: '#F9F5EF', color: 'black', padding: '0 10%'}} position='sticky'>
        <Toolbar style={{padding: 'unset'}}>
          <Typography variant = "h6" style={{flexGrow:1, textAlign: 'left'}} color="inherit" ><Link to='/'><img style={{width: '130px', }} src={logo} alt='lo'></img></Link></Typography>
          <Link to='/' style={{textDecoration: 'none', color: '#1D3551'}} ><Typography variant = "subtitle1" className = {classes.padding} color="inherit" >Accueil </Typography></Link>
          <Link to={{pathname:'https://live.eventtia.com/fr/conditions/Contact'}} target='_blank' style={{textDecoration: 'none', color: '#1D3551'}} ><Typography variant = "subtitle1" className = {classes.padding} color="inherit" >Contact </Typography></Link>
          <Link to={{pathname: 'https://latam.lowcarbonbusinessaction.com'}} style={{textDecoration: 'none', color: '#1D3551', display: 'none'}} target='_blank'><Typography variant = "subtitle1" className = {classes.padding} color="inherit" >About LCBA</Typography></Link>
        </Toolbar>
      </AppBar>
    );
  }
  return(
    <div>
      {drawerActive ? createDrawer() : destroyDrawer()}
    </div>
  );
}

Navbar.propTypes = {
  classes : PropTypes.object.isRequired
};



export default withStyles(styleSheet)(Navbar);